import React, { Suspense, useState, useEffect, useMemo,  useRef} from "react";
import { Canvas, useFrame, useLoader, extend, useThree } from "@react-three/fiber";
import {  Environment, OrbitControls, Sky, Cloud, useProgress, Html,BakeShadows } from "@react-three/drei";

import glsl from 'babel-plugin-glsl/macro'
import MainStage from "./MainStage";
import * as THREE from 'three'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import { Water } from 'three-stdlib'



import { Perf } from 'r3f-perf';
import { Ground2 } from "./Ground2"
import { Ground3 } from "./Ground3"
import { Ground4 } from "./Ground4"
import { Ground5 } from "./Ground5"
import { Ground6 } from "./Ground6"
import { Ground7 } from "./Ground7"
import { Ground8 } from "./Ground8"






export default function Viewer() {

  const SetEnvironment = () => {
    const { gl, scene } = useThree();

    useEffect(() => {
      gl.toneMappingExposure = Math.pow(0.9, 5.0); // Değer düşük olursa sahne daha karanlık olur
      // scene.environment.exposure ayarını yapabilirsiniz eğer ortamınızın böyle bir özelliği varsa
    }, [gl]);

    // Bu bileşen sadece side-effect için kullanıldığından herhangi bir JSX döndürmeye gerek yok
    return null;
  };

const directionalLight = useRef()
  

    const ref = useRef()
    const marker = useRef()
    const marker2 = useRef()
    const [lerping, setLerping] = useState(false)
    const [to, setTo] = useState(new THREE.Vector3(0, 2, 0))
    const [target, setTarget] = useState(new THREE.Vector3(0, 2, 0))

    const [target2, setTarget2] = useState(new THREE.Vector3(0, 0.3, 0))
    
    const [isShown, setIsShown] = useState(true);
   
   const sunPosition= new THREE.Vector3(-0.4, 2.4, 6)       


    const vec = new THREE.Vector3()
    const texture = useLoader(TextureLoader, 'marker2.png')


 
    
    function Animate({ controls, lerping, to, target}) {
    useFrame(state => {
      

      if(lerping){
  state.camera.position.lerp(to, .05)  
    controls.current.target.lerp(target, .05)
    
      }
  
    })
  }

  function Animate2({ target2}) {
    useFrame(state => {
      marker.current.position.lerp(target2, 0.2)

    })
  }
  
  function gotoAnnotation(e) {

   
    
    setTo(new THREE.Vector3(e.point.x, 1.5, e.point.z) )
    setTarget(new THREE.Vector3(e.point.x, 1.5, e.point.z));

   
  
    setLerping(true)
  
    document.getElementById("product-buttons-container").style.display="block";
    document.getElementById("product-popup-container").style.visibility="hidden";
    document.getElementById("product-container-close-button").style.display="block";
   
  }

 
 
  function gotoAnnotation3(e) {
    setTo(new THREE.Vector3(0, 1.5, 0) )
    setTarget(new THREE.Vector3(0, 1.5, 0))
   
    setLerping(true)
 
  }


  function gotoAnnotation4(e) {
    setTo(new THREE.Vector3(-1.2, 2.0, -0.23) );
    setTarget(new THREE.Vector3(-2.8, 1.4, -0.85));
  
    setLerping(true);
    
  
    document.getElementById("product-buttons-container").style.display="none";
    document.getElementById("main-buttons-container").style.display="block";
    document.getElementById("product-popup-container").style.visibility="visible";
    document.getElementById("product-1-info-container").style.display="block";
    document.getElementById("product-2-info-container").style.display="none";
    document.getElementById("product-3-info-container").style.display="none";
    document.getElementById("product-4-info-container").style.display="none";
    document.getElementById("product-5-info-container").style.display="none";
    document.getElementById("product-container-close-button").style.display="none";
    
    
  }

  function gotoAnnotation5(e) {
    setTo(new THREE.Vector3(-0.39, 2.0, -2.8) )
    setTarget(new THREE.Vector3(-0.3, 1.4, -3.8))
    
    setLerping(true)

  document.getElementById("product-buttons-container").style.display="none";
  document.getElementById("main-buttons-container").style.display="block";
  document.getElementById("product-popup-container").style.visibility="visible";
  document.getElementById("product-1-info-container").style.display="none";
  document.getElementById("product-2-info-container").style.display="block";
  document.getElementById("product-3-info-container").style.display="none";
  document.getElementById("product-4-info-container").style.display="none";
  document.getElementById("product-5-info-container").style.display="none";
  document.getElementById("product-container-close-button").style.display="none";
  }


  function gotoAnnotation6(e) {
    setTo(new THREE.Vector3(  2.1, 2.0, -0.72) )
    setTarget(new THREE.Vector3(  3.0, 1.4, -1.0))
    
    setLerping(true)
    
    document.getElementById("product-buttons-container").style.display="none";
    document.getElementById("main-buttons-container").style.display="block";
    document.getElementById("product-popup-container").style.visibility="visible";
    document.getElementById("product-1-info-container").style.display="none";
    document.getElementById("product-2-info-container").style.display="none";
    document.getElementById("product-3-info-container").style.display="block";
    document.getElementById("product-4-info-container").style.display="none";
    document.getElementById("product-5-info-container").style.display="none";
    document.getElementById("product-container-close-button").style.display="none";
  
    
  }

  function gotoAnnotation7(e) {
    setTo(new THREE.Vector3(  1.4, 2.0, 1.2) )
    setTarget(new THREE.Vector3(  2.0, 1.4, 2.2))
    
    setLerping(true)

    document.getElementById("product-buttons-container").style.display="none";
    document.getElementById("main-buttons-container").style.display="block";
    document.getElementById("product-popup-container").style.visibility="visible";
    document.getElementById("product-1-info-container").style.display="none";
    document.getElementById("product-2-info-container").style.display="none";
    document.getElementById("product-3-info-container").style.display="none";
    document.getElementById("product-4-info-container").style.display="block";
    document.getElementById("product-5-info-container").style.display="none";
    document.getElementById("product-container-close-button").style.display="none";

  }

    
  function gotoAnnotation8(e) {
    setTo(new THREE.Vector3(  -0.94, 2.0, 1.75) )
    setTarget(new THREE.Vector3( -2.1, 1.4, 3.1))
    
    setLerping(true)

   

    document.getElementById("product-buttons-container").style.display="none";
    document.getElementById("main-buttons-container").style.display="block";
    document.getElementById("product-popup-container").style.visibility="visible";
    document.getElementById("product-1-info-container").style.display="none";
    document.getElementById("product-2-info-container").style.display="none";
    document.getElementById("product-3-info-container").style.display="none";
    document.getElementById("product-4-info-container").style.display="none";
    document.getElementById("product-5-info-container").style.display="block";
    document.getElementById("product-container-close-button").style.display="none";
    
  }








  function updateMarker(e) {
   
    setTarget2(new THREE.Vector3(e.point.x, 0.1, e.point.z))
  
  }

  const [hovered, set] = useState(false)
  useEffect(() => {
    if (hovered) {
      document.body.style.cursor = `pointer`
      return () => (document.body.style.cursor = `auto`)
    }
  }, [hovered])

  function Loader() {
    const { active, progress, errors, item, loaded, total } = useProgress()
    var num= Number({progress})
  
    return <Html center>
     
     
      <div id="preloader-container"><img id="gift" src="krc-gif.gif" alt="Be patient..." /></div>


      <div id="loading-container">
      <p> {progress.toFixed()} % Yükleniyor </p>
      </div>
    </Html>
  }

  
  return (
    
    <>
    
      <Canvas shadows dpr={[1, 2]} camera={{ position: [3.97,1.5,0.3],  fov: 50 }}  >
      <Suspense fallback={<Loader />}>
      {/* <Fire position={[-20.6, 5.76, -10.55]} scale={[0.2,1.4,0.15]} /> */}
      
      <Environment
    
    background
        blur={0}
        Environment files="/neutral.hdr"
      rotation={[0,Math.PI ,  0] }
      
/>
{/* 
 <Perf />  */}
        
        <OrbitControls ref={ref} target={[2.98,1.5,-1.38]}   minDistance={1.7} maxDistance={2}  enableZoom={false} minPolarAngle={Math.PI / 4} maxPolarAngle={Math.PI /1.7} />
        <Animate controls={ref} lerping={lerping} to={to} target={target} />
        <Animate2  target2={target2}/>

        <mesh  onClick={(e) => (e.stopPropagation(), gotoAnnotation(e))} onPointerMove={(e) => (e.stopPropagation(), updateMarker(e))} onPointerOver={(e) => set(true)} onPointerOut={() => set(false)}
      position-y={ 0.01 } position-z={ 0} position-x={ 0 } rotation-x={ - Math.PI * 0.5 } scale={ 1 }>
            <circleGeometry args={[5.7, 32]} />
            <meshStandardMaterial  transparent={true}
            opacity={0}  />
        </mesh>

        <mesh position-y={ 2 }  ref={marker} name="marker"  rotation-x={ - Math.PI * 0.5 }>
          <planeGeometry args={[0.4, 0.4, 1, 1]} />
          <meshStandardMaterial
          
            transparent={true}
            opacity={1}
            depthWrite={false}
            map={texture}
          />
        </mesh>

    
  
        <SetEnvironment />
        
          <MainStage position={[0, 0, 0]} />
           <Ground2 position={[6.21, 0.21, -1.92]} rotation={[0, -1.3, 0]} onClick={(e) => (e.stopPropagation(), gotoAnnotation6())} />   
     <Ground3 position={[-5.70, 0.21, -1.88]} rotation={[0, Math.PI/2.5, 0]} onClick={(e) => (e.stopPropagation(), gotoAnnotation4())}/>  
    <Ground5 position={[-0.22, 0.1, -6.15]} onClick={(e) => (e.stopPropagation(), gotoAnnotation5())}/>  
       <Ground6 position={[-3.47, 0.21, 4.85]} rotation={[0, 2.5, 0]} onClick={(e) => (e.stopPropagation(), gotoAnnotation8())}/>   
     <Ground8 position={[3.67, -0.01, 4.85]} rotation={[0, -2.6, 0]} onClick={(e) => (e.stopPropagation(), gotoAnnotation7())}/> 
     
           <Ground4/>    
           <Ground7/>  
         <Sky scale={1000} sunPosition={sunPosition} turbidity={0.1} /> 
         <BakeShadows /> 
       
        </Suspense>
       

    
        

               
           
           </Canvas>

    
      
           <div className="overlay">
        <button id="real-close-button"  onClick={(e) => (e.stopPropagation(), gotoAnnotation3())}>
          <b>close</b>
        </button>
      </div>

      <div id="overlay2"
      className="overlay2">
        <button id="button2"   onClick={(e) => (e.stopPropagation(), gotoAnnotation4())}>
          <b>valeria</b>
        </button>
      </div> 

      <div className="overlay">
        <button id="button3"   onClick={(e) => (e.stopPropagation(), gotoAnnotation5())}>
          <b>Motto</b>
        </button>
      </div>

      <div className="overlay">
        <button id="button4"   onClick={(e) => (e.stopPropagation(), gotoAnnotation6())}>
          <b>cift</b>
        </button>
      </div>

      <div className="overlay">
        <button id="button5"   onClick={(e) => (e.stopPropagation(), gotoAnnotation7())}>
          <b>mavi</b>
        </button>
      </div>

      <div className="overlay">
        <button id="button6"   onClick={(e) => (e.stopPropagation(), gotoAnnotation8())}>
          <b>tek</b>
        </button>

        
      </div>

       
    </>
  );

 
  
}



