/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Ground7(props) {
  const { nodes, materials } = useGLTF("/tabloR.glb");
  return (
    <group {...props} dispose={null}>
      <group
        position={[-2.4924, 0.4502, 5.8844]}
        rotation={[0, -1.1202, 0]}
        scale={0.0009}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.CUPlane002.geometry}
          material={materials.chair_mat}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.CUPlane002_1.geometry}
          material={materials["Material.020"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Chair001.geometry}
        material={materials["Material.025"]}
        position={[6.7477, 0.2902, -0.5975]}
        rotation={[-Math.PI, 0.2311, -Math.PI]}
      />
      <group
        position={[2.5998, 0, 6.1859]}
        rotation={[0, 0.153, 0]}
        scale={0.5758}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Table_1.geometry}
          material={materials.Wood}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Table_2.geometry}
          material={materials.mETAL}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Italian_chair.geometry}
        material={materials.Wood}
        position={[5.0957, 0, 4.3391]}
        rotation={[Math.PI, -0.0044, Math.PI]}
        scale={1.0354}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_2002.geometry}
        material={materials["book.dracula"]}
        position={[2.6567, 0.4343, 5.9856]}
        rotation={[0, -0.3285, -Math.PI]}
        scale={0.1578}
      />
      <group
        position={[2.8934, 0.4156, 6.0873]}
        rotation={[0, 0.81, 0]}
        scale={1.9933}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder002.geometry}
          material={materials.material_01}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder002_1.geometry}
          material={materials.material_02}
        />
      </group>
      <group
        position={[0.1763, 0.8085, 4.5168]}
        rotation={[-3.0847, -0.0253, -3.1303]}
        scale={0.0101}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh.geometry}
          material={materials["Material.025"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_1.geometry}
          material={materials["Material.026"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane007.geometry}
        material={materials["Material.029"]}
        position={[0.1762, 0.9329, 4.5062]}
        rotation={[1.6573, -0.0013, 3.1263]}
        scale={[0.3207, 0.1807, 0.4202]}
      />
      <group
        position={[2.8148, 0.8085, -3.7655]}
        rotation={[-0.0668, -0.5514, -0.0252]}
        scale={0.0101}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh017.geometry}
          material={materials["Material.025"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh017_1.geometry}
          material={materials["Material.026"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane008.geometry}
        material={materials["Material.030"]}
        position={[2.8091, 0.9329, -3.7565]}
        rotation={[1.4976, -0.0461, 0.5607]}
        scale={[0.5082, 0.1807, 0.4202]}
      />
      <group
        position={[-2.7494, 0.8085, -3.7649]}
        rotation={[-0.0774, 0.7446, 0.0623]}
        scale={0.0101}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh019.geometry}
          material={materials["Material.025"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh019_1.geometry}
          material={materials["Material.026"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane009.geometry}
        material={materials["Material.027"]}
        position={[-2.7423, 0.9329, -3.757]}
        rotation={[1.5066, 0.058, -0.7342]}
        scale={[0.5105, 0.1807, 0.4202]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.picture.geometry}
        material={materials["178c48b8f65689327e5625340d34cc39"]}
        position={[-5.2834, 1.3772, 1.7071]}
        rotation={[Math.PI / 2, 0, -1.9445]}
        scale={[1.0771, 0.8066, 0.8066]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.frame.geometry}
        material={materials.frame}
        position={[-5.283, 1.3772, 1.7069]}
        rotation={[Math.PI / 2, 0, -1.9445]}
        scale={[1.3473, 0.7972, 0.9186]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.bg.geometry}
        material={materials["Material.028"]}
        position={[-5.2871, 1.3772, 1.7082]}
        rotation={[Math.PI / 2, 0, -1.9445]}
        scale={[1.3473, 0.7972, 0.9186]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.picture001.geometry}
        material={materials["178c48b8f65689327e5625340d34cc39"]}
        position={[5.2618, 1.3772, 1.7819]}
        rotation={[Math.PI / 2, 0, 1.8412]}
        scale={[1.0859, 0.8066, 0.8066]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.frame001.geometry}
        material={materials.frame}
        position={[5.2642, 1.3772, 1.7826]}
        rotation={[Math.PI / 2, 0, 1.8412]}
        scale={[1.3583, 0.7972, 0.9186]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.bg001.geometry}
        material={materials["Material.028"]}
        position={[5.2682, 1.3772, 1.7841]}
        rotation={[Math.PI / 2, 0, 1.8412]}
        scale={[1.3583, 0.7972, 0.9186]}
      />
    </group>
  );
}

useGLTF.preload("/tabloR.glb");
