/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Ground4(props) {
  const { nodes, materials } = useGLTF("/cicek-aksesuarR.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Pot002.geometry}
        material={materials.Pot}
        position={[-5.4563, 0.4391, -3.3195]}
        scale={[0.0575, 0.074, 0.0575]}
      />
      <group
        position={[-5.3743, 0.3563, -3.3587]}
        rotation={[Math.PI, -1.3156, Math.PI]}
        scale={[0.004, 0.0057, 0.004]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cone001001_1.geometry}
          material={materials.Mirror}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cone001001_2.geometry}
          material={materials.mETAL}
        />
      </group>
      <group position={[-6.3728, 0, -0.3244]} rotation={[0, -1.1637, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.CUBezierCurve.geometry}
          material={materials.mETAL}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.CUBezierCurve_1.geometry}
          material={materials.Almofada}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Chair.geometry}
        material={materials["Material.025"]}
        position={[5.8215, 0.494, -3.6003]}
        rotation={[0, -0.4123, 0]}
        scale={0.9968}
      />
      <group
        position={[-6.7555, 0.1263, -0.9035]}
        rotation={[0, 0.6699, 0]}
        scale={0.022}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Circle003.geometry}
          material={materials["mETAL.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Circle003_1.geometry}
          material={materials["Black.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Circle003_2.geometry}
          material={materials["Inside lamp.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Circle003_3.geometry}
          material={materials["Bulb.001"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Guzzini_style_lamp.geometry}
        material={materials["Guzzini style lamp"]}
        position={[-3.3987, 0, 6.2968]}
        rotation={[Math.PI, -0.6136, Math.PI]}
        scale={0.779}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.BezierCurve006.geometry}
        material={materials["Material.014"]}
        position={[-5.4512, 0.5302, -3.3051]}
        scale={[0.8093, 0.6514, 0.8093]}
      />
      <group
        position={[-2.1798, 0.2158, -8.705]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.0086}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Howea_f__A001_1.geometry}
          material={materials.Material__0}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Howea_f__A001_2.geometry}
          material={materials.Material__1}
        />
      </group>
      <group
        position={[1.9746, 0.2436, -9.445]}
        rotation={[Math.PI / 2, 0, 1.4224]}
        scale={0.0104}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Howea_f__A001001_1.geometry}
          material={materials["Material.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Howea_f__A001001_2.geometry}
          material={materials["Material__1.001"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Pot001.geometry}
        material={materials["Pot.001"]}
        position={[-2.1813, -0.0053, -8.7163]}
        scale={[0.1187, 0.1527, 0.1187]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Pot003.geometry}
        material={materials["Pot.001"]}
        position={[1.9816, -0.0053, -9.4432]}
        scale={[0.1187, 0.1527, 0.1187]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere.geometry}
        material={materials["Material.015"]}
        position={[1.7097, 0.3909, -6.4921]}
        scale={0.3955}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere001.geometry}
        material={materials["Material.015"]}
        position={[-2.5054, 0.2006, -7.5099]}
        scale={0.2014}
      />
      <group
        position={[2.3412, 0.4854, 6.2494]}
        rotation={[Math.PI / 2, 0, 1.7906]}
        scale={0.0029}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Howea_f__A001002_1.geometry}
          material={materials.Material__0}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Howea_f__A001002_2.geometry}
          material={materials.Material__1}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Pot004.geometry}
        material={materials["Pot.001"]}
        position={[2.3451, 0.4103, 6.2497]}
        rotation={[Math.PI, -1.351, Math.PI]}
        scale={[0.0403, 0.0518, 0.0403]}
      />
      <group position={[6.7381, 0.456, -0.5972]} scale={0.3865}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Circle006.geometry}
          material={materials.MI_MZRa_Monstera_B02a}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Circle006_1.geometry}
          material={materials.MI_MZRa_Monstera_B02b}
        />
      </group>
      <group
        position={[-4.8518, 0.0058, 4.3506]}
        rotation={[0, 1.1186, 0]}
        scale={0.5075}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Circle005.geometry}
          material={materials.MI_MZRa_Monstera_B02a}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Circle005_1.geometry}
          material={materials.MI_MZRa_Monstera_B02b}
        />
      </group>
      <group
        position={[5.37, 0.1796, 3.8119]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.0058}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Howea_f__A001003_1.geometry}
          material={materials.Material__0}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Howea_f__A001003_2.geometry}
          material={materials.Material__1}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Pot005.geometry}
        material={materials["Pot.001"]}
        position={[5.369, 0.0309, 3.8043]}
        scale={[0.0798, 0.1027, 0.0798]}
      />
      <group
        position={[-4.8459, 0.1796, -3.7722]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.0058}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Howea_f__A001004_1.geometry}
          material={materials.Material__0}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Howea_f__A001004_2.geometry}
          material={materials.Material__1}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Pot006.geometry}
        material={materials["Pot.001"]}
        position={[-4.847, 0.0309, -3.7798]}
        scale={[0.0798, 0.1027, 0.0798]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_2.geometry}
        material={materials["book.the_poems"]}
        position={[5.7698, 0.4794, -3.2594]}
        rotation={[-Math.PI, 0, 0]}
        scale={0.164}
      />
    </group>
  );
}

useGLTF.preload("/cicek-aksesuarR.glb");
