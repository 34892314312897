/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useMemo, useRef, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { TextureLoader } from 'three'

import { useLoader } from '@react-three/fiber'
import { Html } from '@react-three/drei';

export function Ground3(props) {





  const group = useRef();
  const [selected, setSelected] = useState(0)
  const { nodes, materials, animations } = useGLTF("/valeria2R.glb");
  const materialOverrides = useMemo(() => {
    return {
      0: materials.yorganon,
      1: materials.yorganon2,
      2: materials.yorganon3,
      3: materials.yorganon4,
     
    }
  }, [materials])

  const materialOverrides2 = useMemo(() => {
    return {
      0: materials.yorganarka,
      1: materials.yorganarka2,
      2: materials.yorganarka3,
      3: materials.yorganarka4,
     
    }
  }, [materials])

  const materialOverrides3 = useMemo(() => {
    return {
      0: materials.Yastikarka,
      1: materials.Yastikarka2,
      2: materials.Yastikarka3,
      3: materials.Yastikarka4,
    }
  }, [materials])

  const materialOverrides4 = useMemo(() => {
    return {
      0: materials.yastikon,
      1: materials.yastikon2,
      2: materials.yastikon3,
      3: materials.yastikon4,
    }
  }, [materials])

  return (
    <>

<Html    position={[-3.7, 1.5, -1.2]}>
    <div className="overlay">
        <button id="button19"   onClick={() => setSelected(0)} >
         turkuaz
        </button>
      </div>

      <div className="overlay">
        <button id="button20"   onClick={() => setSelected(1)}>
         silver
        </button>

        <div className="overlay">
        <button id="button21"   onClick={() => setSelected(2)}>
         rose
        </button>
      </div>

      <div className="overlay">
        <button id="button22"   onClick={() => setSelected(3)}>
         gul
        </button>
      </div>
      </div>


     
   
      </Html>
 <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube010.geometry}
        material={materials["Material.019"]}
      />
      <group position={[0.005, -0.14, -0.81]} scale={0.01}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh019.geometry}
          material={materials["ictahta.009"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh019_1.geometry}
          material={materials["tahta.013"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh019_2.geometry}
          material={materials["Material.020"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.yastikon.geometry}
        material={materialOverrides4[selected]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.yastikarka.geometry}
        material={materialOverrides3[selected]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube004.geometry}
        material={materials["Material.021"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.yorganarka.geometry}
        material={materialOverrides2[selected]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.yorganon.geometry}
        material={materialOverrides[selected]}
      />
      <group position={[-0.405, -0.094, -0.039]} scale={-0.071}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001.geometry}
          material={materials.yorganon2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_1.geometry}
          material={materials.Yastikarka2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_2.geometry}
          material={materials.yorganarka2}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane.geometry}
        material={materials.yastikon2}
        position={[-0.275, -0.374, 0.047]}
        scale={0.026}
      />
      <group position={[0, -0.126, 0]} scale={0.11}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane001.geometry}
          material={materials.yorganon3}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane001_1.geometry}
          material={materials.yorganarka3}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane001_2.geometry}
          material={materials.Yastikarka3}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane001_3.geometry}
          material={materials.yastikon3}
        />
      </group>
      <group position={[0.329, -0.121, 0]} scale={0.074}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane002.geometry}
          material={materials.yorganon4}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane002_1.geometry}
          material={materials.yorganarka4}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane002_2.geometry}
          material={materials.yastikon4}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane002_3.geometry}
          material={materials.Yastikarka4}
        />
      </group>
    </group>
    </>
  );
}

useGLTF.preload("/valeria2R.glb");