/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

export default function Model(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/alan-ayri.glb");
  const { actions } = useAnimations(animations, group);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="Plane003"
          castShadow
          receiveShadow
          geometry={nodes.Plane003.geometry}
          material={materials["Material.019"]}
          position={[-6.838, 0.6265, -2.1285]}
          rotation={[Math.PI / 2, 0, -1.2556]}
          scale={[2.8833, 0.8158, 1.3439]}
        />
        <group
          name="Sketchfab_model"
          rotation={[-Math.PI / 2, 0, 0]}
          scale={0.0097}
        >
          <group name="modelobjcleanermaterialmergergles" />
        </group>
        <group
          name="Sketchfab_model001"
          position={[5.6623, 0.5288, -3.1971]}
          rotation={[-Math.PI, 0, 0]}
          scale={0.164}
        >
          <group name="EA_Poe_The_Poems_2objcleanermaterialmergergles" />
        </group>
        <mesh
          name="Plane004"
          castShadow
          receiveShadow
          geometry={nodes.Plane004.geometry}
          material={materials["Material.016"]}
          position={[-4.224, 0.5888, 6.0098]}
          rotation={[Math.PI / 2, 0, -2.5201]}
          scale={[2.8833, 0.8158, 1.3439]}
        />
        <mesh
          name="Plane005"
          castShadow
          receiveShadow
          geometry={nodes.Plane005.geometry}
          material={materials["Material.017"]}
          position={[4.2843, 0.5, 6.0628]}
          rotation={[Math.PI / 2, 0, 2.514]}
          scale={[2.8833, 0.8158, 1.3439]}
        />
        <mesh
          name="Plane006"
          castShadow
          receiveShadow
          geometry={nodes.Plane006.geometry}
          material={materials["Material.031"]}
          position={[7.4852, 0.3351, -2.3983]}
          rotation={[Math.PI / 2, 0, 1.2485]}
          scale={[2.8833, 0.8158, 1.3439]}
        />
        <group name="Object_3001" position={[0, 3.9383, 0.0002]} scale={1.2196}>
          <mesh
            name="Object_3002_1"
            castShadow
            receiveShadow
            geometry={nodes.Object_3002_1.geometry}
            material={materials["Material.018"]}
          />
          <mesh
            name="Object_3002_2"
            castShadow
            receiveShadow
            geometry={nodes.Object_3002_2.geometry}
            material={materials["Material.032"]}
          />
        </group>
        <group name="Object_3002">
          <mesh
            name="Object_3001_1"
            castShadow
            receiveShadow
            geometry={nodes.Object_3001_1.geometry}
            material={materials["Material.034"]}
          />
          <mesh
            name="Object_3001_2"
            castShadow
            receiveShadow
            geometry={nodes.Object_3001_2.geometry}
            material={materials["Material.035"]}
          />
          <mesh
            name="Object_3001_3"
            castShadow
            receiveShadow
            geometry={nodes.Object_3001_3.geometry}
            material={materials["Material.036"]}
          />
          <mesh
            name="Object_3001_4"
            castShadow
            receiveShadow
            geometry={nodes.Object_3001_4.geometry}
            material={materials["Material.037"]}
          />
          <mesh
            name="Object_3001_5"
            castShadow
            receiveShadow
            geometry={nodes.Object_3001_5.geometry}
            material={materials["Material.038"]}
          />
        </group>
        <mesh
          name="Object_1"
          castShadow
          receiveShadow
          geometry={nodes.Object_1.geometry}
          material={materials["Material_2.002"]}
          scale={0.1356}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/alan-ayri.glb");
